exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Dashboard_container__3CqYQ {\n  display: grid;\n  grid-template-columns: 500px 1fr; }\n", ""]);

// exports
exports.locals = {
	"container": "Dashboard_container__3CqYQ"
};