import React from "react";
import style from "./Input.module.scss";
import classnames from "classnames";

const Input = ({ name, label, small, center, ...props }) => {
    const className = classnames(style["input"], {
        [style["small"]]: small,
        [style["center"]]: center
    });

    return (
        <div className={style["container"]}>
            <label className={style["label"]} htmlFor={name}>
                {label}
            </label>
            <input className={className} type="text" name={name} {...props} />
        </div>
    );
};

export const Horizontal = ({ children }) => {
    return <div className={style["horizontal"]}>{children}</div>;
};

export default Input;
