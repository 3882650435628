import { RECORD } from "../actions/actionTypes";

export default function idReducer(state = 1, action) {
    switch (action.type) {
        case RECORD:
            return state + 1;
        default:
            return state;
    }
}
