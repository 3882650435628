exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CurrencyOverview_container__A0GQY {\n  margin: 10px;\n  cursor: pointer;\n  width: 320px;\n  height: 80px;\n  border: 1px solid #eceff1;\n  border-radius: 4px;\n  padding: 15px 20px 15px 20px; }\n  .CurrencyOverview_container__A0GQY:hover {\n    border: 1px solid #b3b3b3;\n    border: 1px solid #2196f3;\n    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }\n\n.CurrencyOverview_header__15t1q {\n  display: -webkit-flex;\n  display: flex;\n  -webkit-align-items: center;\n          align-items: center;\n  font-size: 20px; }\n\n.CurrencyOverview_details__1UHq_ {\n  margin-top: 10px;\n  margin-left: 3px;\n  display: grid;\n  grid-template-columns: 70px 100px 150px; }\n\n.CurrencyOverview_value__2pPWK {\n  font-size: 18px;\n  overflow: hidden;\n  text-overflow: ellipsis; }\n\n.CurrencyOverview_value-title__hOHjd {\n  font-size: 13px;\n  color: #444; }\n", ""]);

// exports
exports.locals = {
	"container": "CurrencyOverview_container__A0GQY",
	"header": "CurrencyOverview_header__15t1q",
	"details": "CurrencyOverview_details__1UHq_",
	"value": "CurrencyOverview_value__2pPWK",
	"value-title": "CurrencyOverview_value-title__hOHjd"
};