import React from "react";
import style from "./App.module.scss";
import { Router } from "@reach/router";
import Dashboard from "./Dashboard";
import TransactionList from "./TransactionList";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { record, updateTransaction, deleteTransaction } from "../redux/actions";

const App = ({
    currencies,
    transactions,
    record,
    updateTransaction,
    deleteTransaction,
    id
}) => {
    return (
        <div className={style["container"]}>
            <Router>
                <Dashboard
                    path="/"
                    currencies={currencies}
                    transactions={transactions}
                    record={record}
                    id={id}
                />
                <TransactionList
                    path="/transactions/:currency"
                    currencies={currencies}
                    transactions={transactions}
                    updateTransaction={updateTransaction}
                    deleteTransaction={deleteTransaction}
                />
            </Router>
        </div>
    );
};

const mapStateToProps = state => ({
    currencies: state.currencies,
    transactions: state.transactions,
    id: state.id
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { record, updateTransaction, deleteTransaction },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
