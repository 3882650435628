exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TransactionList_container__2IOqC {\n  width: 600px;\n  margin: 0 auto;\n  margin-top: 50px; }\n\n.TransactionList_header__3Nrfl {\n  display: -webkit-flex;\n  display: flex;\n  -webkit-align-items: center;\n          align-items: center;\n  font-size: 24px; }\n\n.TransactionList_list__3Pvct {\n  margin-top: 20px;\n  width: 450px; }\n\n.TransactionList_list-item-header__pbDOo {\n  display: grid;\n  grid-template-columns: 15% 35% 35% 15%;\n  font-weight: 700; }\n\n.TransactionList_list-item__1qm5M {\n  display: grid;\n  grid-template-columns: 15% 35% 35% 15%;\n  cursor: pointer;\n  padding: 3px;\n  border-radius: 2px; }\n  .TransactionList_list-item__1qm5M:nth-child(even) {\n    background-color: #f2f2f2; }\n  .TransactionList_list-item__1qm5M:hover > .TransactionList_controls__-Ag85 {\n    opacity: 1; }\n\n.TransactionList_cell__3LQJL {\n  display: -webkit-flex;\n  display: flex;\n  -webkit-align-items: center;\n          align-items: center; }\n  .TransactionList_cell__3LQJL.TransactionList_end__1_Yzf {\n    -webkit-justify-content: flex-end;\n            justify-content: flex-end; }\n\n.TransactionList_controls__-Ag85 {\n  opacity: 0.15;\n  padding: 0 2px 0 12px; }\n\n.TransactionList_icon-container__3jfR5 {\n  display: -webkit-flex;\n  display: flex;\n  -webkit-justify-content: space-around;\n          justify-content: space-around;\n  padding-top: 3px; }\n\n.TransactionList_go-back__2BrTY {\n  color: blue !important;\n  margin-top: 30px;\n  width: 450px;\n  text-align: end; }\n", ""]);

// exports
exports.locals = {
	"container": "TransactionList_container__2IOqC",
	"header": "TransactionList_header__3Nrfl",
	"list": "TransactionList_list__3Pvct",
	"list-item-header": "TransactionList_list-item-header__pbDOo",
	"list-item": "TransactionList_list-item__1qm5M",
	"controls": "TransactionList_controls__-Ag85",
	"cell": "TransactionList_cell__3LQJL",
	"end": "TransactionList_end__1_Yzf",
	"icon-container": "TransactionList_icon-container__3jfR5",
	"go-back": "TransactionList_go-back__2BrTY"
};