exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TransactionForm_container__bAK0G {\n  width: 350px;\n  padding: 20px 30px 20px 30px;\n  height: 245px;\n  border: 1px solid #eceff1;\n  border-radius: 4px;\n  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }\n  .TransactionForm_container__bAK0G.TransactionForm_editing__34DrA {\n    margin: 10px auto; }\n\n.TransactionForm_title__19o5z {\n  margin-bottom: 13px;\n  font-size: 18.5px;\n  font-weight: 500; }\n\n.TransactionForm_form__3wsMf {\n  display: grid;\n  grid-template-rows: 70px 60px 15px 55px; }\n\n.TransactionForm_messages__2eGDD {\n  display: grid;\n  grid-template-columns: 65% 35%;\n  font-size: 15px; }\n", ""]);

// exports
exports.locals = {
	"container": "TransactionForm_container__bAK0G",
	"editing": "TransactionForm_editing__34DrA",
	"title": "TransactionForm_title__19o5z",
	"form": "TransactionForm_form__3wsMf",
	"messages": "TransactionForm_messages__2eGDD"
};