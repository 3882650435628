import React from "react";
import style from "./CurrencyList.module.scss";
import { Link } from "@reach/router";
import CurrencyOverview from "./CurrencyOverview";
import convertPrice from "../convertPrice";

const CurrencyList = ({ currencies, transactions }) => {
    if (transactions.length === 0) {
        return (
            <div
                style={{
                    margin: "200px auto",
                    fontSize: "20px",
                    color: "grey",
                    textAlign: "center"
                }}
            >
                <div>Record a transaction to get started.</div>
                <div>Your currencies will appear here.</div>
            </div>
        );
    }

    let ownedCurrencies = {};
    transactions.forEach(transaction => {
        const { currency, units, price } = transaction;
        const currencySummary = ownedCurrencies[currency];
        const floatPrice = convertPrice(price);

        if (currencySummary) {
            currencySummary.totalTransactions += 1;
            currencySummary.totalUnits += units;
            currencySummary.totalPrice += floatPrice;
        } else {
            ownedCurrencies[currency] = {
                totalTransactions: 1,
                totalUnits: units,
                totalPrice: floatPrice
            };
        }
    });
    ownedCurrencies = Object.entries(ownedCurrencies);

    return (
        <div className={style["container"]}>
            {ownedCurrencies.map(currency => {
                const [name, details] = currency;
                const { totalPrice, totalUnits, totalTransactions } = details;
                return (
                    <Link key={name} to={`/transactions/${name}`}>
                        <CurrencyOverview
                            details={currencies[name]}
                            totalTransactions={totalTransactions}
                            totalPrice={totalPrice}
                            totalUnits={totalUnits}
                        />
                    </Link>
                );
            })}
        </div>
    );
};

export default CurrencyList;
