import React from "react";
import style from "./TransactionForm.module.scss";
import { withFormik } from "formik";
import { object, number, string } from "yup";
import Creatable from "react-select/lib/Creatable";
import Input, { Horizontal } from "./Controls/Input";
import Button, { End } from "./Controls/Button";
import classnames from "classnames";

function stripLeadingZeroes(first) {
    if (/^0+$/.test(first)) {
        first = "0";
    } else if (/^0+\d+/.test(first)) {
        first = first.replace(/^(0+)(\d+)/, "$2");
    }
    return first;
}

function fixRate(value) {
    if (value === "") return value;
    let [first, second] = value.split(".");

    first = stripLeadingZeroes(first);
    if (second === undefined || second === "") {
        second = "00";
    } else if (second.length === 1) {
        second = second + "0";
    }

    return [first, second].join(".");
}

function fixUnits(value) {
    if (value === "") return value;
    let [first, second] = value.split(".");

    first = stripLeadingZeroes(first);
    second = second ? second.replace(/0+$/, "") : "";

    if (second !== "") {
        return [first, second].join(".");
    } else return first;
}

const InnerForm = ({
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setFieldValue,
    currencies,
    mode
}) => {
    const options = Object.entries(currencies).map(entry => {
        const [value, details] = entry;
        const { logo, label } = details;
        return {
            value,
            label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={logo}
                        alt={`${label} logo`}
                        height="16"
                        width="16"
                    />
                    <div style={{ marginLeft: "10px" }}>{label}</div>
                </div>
            )
        };
    });

    let currencyError =
        touched.currency && errors.currency ? errors.currency : "";
    let unitError = touched.units && errors.units ? errors.units : "";
    let priceError = touched.price && errors.price ? errors.price : "";

    const allErrors = [currencyError, unitError, priceError];
    const error = allErrors.find(err => err !== "");

    function handleCurrencyChange(value) {
        setFieldValue("currency", value);
    }

    function handlePriceChange(event) {
        const { value } = event.target;
        const regex = /^$|^[0-9]\d*\.?(\d{1,2})?$/;
        const matches = regex.test(value);
        if (matches) setFieldValue("price", value);
    }

    function handlePriceBlur(event) {
        handleBlur(event);
        let { value } = event.target;
        if (value !== "") {
            value = `$${fixRate(value)} AUD`;
            setFieldValue("price", value);
        }
    }

    function handlePriceFocus(event) {
        let { value } = event.target;
        let original = value.match(/[0-9]\d*\.?(\d{1,2})?/);
        if (value !== "") {
            setFieldValue("price", original ? original[0] : "");
        }
    }

    function handleUnitsChange(event) {
        const { value } = event.target;
        if (/^\d*(\d+\.)?(\d*)?$/.test(value)) {
            setFieldValue("units", value);
        }
    }

    function handleUnitsBlur(event) {
        handleBlur(event);
        let { value } = event.target;
        if (value !== "") {
            setFieldValue("units", parseFloat(fixUnits(value)));
        }
    }

    let price = values.price.match(/[0-9]\d*\.?(\d{1,2})?/);
    if (price) {
        price = parseFloat(price);
    }

    const className = classnames(style["container"], {
        [style["editing"]]: mode === "editing"
    });
    return (
        <div className={className}>
            <div className={style["title"]}>Transaction</div>
            <form className={style["form"]} onSubmit={handleSubmit}>
                <div>
                    <label
                        style={{
                            fontSize: "15px",
                            color: "#444"
                        }}
                    >
                        Currency
                    </label>
                    <Creatable
                        styles={{
                            control: base => ({
                                ...base,
                                boxShadow: "none"
                            })
                        }}
                        name="currency"
                        value={values.currency}
                        onChange={handleCurrencyChange}
                        options={options}
                    />
                </div>
                <Horizontal>
                    <Input
                        name="units"
                        label={"Units"}
                        small
                        value={values.units}
                        onChange={handleUnitsChange}
                        onBlur={handleUnitsBlur}
                    />
                    <Input
                        name="price"
                        label="Price"
                        small
                        value={values.price}
                        onChange={handlePriceChange}
                        onFocus={handlePriceFocus}
                        onBlur={handlePriceBlur}
                    />
                </Horizontal>
                <div className={style["messages"]}>
                    <div style={{ color: "#f44336" }}>{error ? error : ""}</div>
                    {price && values.units && (
                        <div
                            style={{
                                color: "#4caf50",
                                textAlign: "right"
                            }}
                        >
                            {`$${(price / values.units).toFixed(2)} / unit`}
                        </div>
                    )}
                </div>
                <End>
                    <Button type="submit" onClick={handleSubmit}>
                        {mode === "editing" ? "Save" : "Record"}
                    </Button>
                </End>
            </form>
        </div>
    );
};

const TransactionForm = withFormik({
    mapPropsToValues: props => {
        if (props.transaction) {
            const { currency, units, price } = props.transaction;
            return {
                currency: { ...props.currencies[currency], value: currency },
                units,
                price
            };
        }
        return {
            currency: "",
            units: "",
            price: ""
        };
    },
    validationSchema: object().shape({
        currency: string().required("Enter the currency purchased"),
        units: number().required("Enter the amount of units purchased"),
        price: string().required("Enter the purchase price")
    }),
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
        const currency = values.currency.value;
        const { price, units } = values;
        const { id, mode, record, updateTransaction } = props;
        const editing = mode === "editing";

        const newTransaction = { id, currency, units, price };
        if (editing) {
            updateTransaction(newTransaction);
        } else {
            record(newTransaction);
        }
        resetForm();
        setSubmitting(false);
        if (editing) {
            props.resetEditing();
        }
    }
})(InnerForm);

export default TransactionForm;
