import { RECORD, DELETE, UPDATE } from "./actionTypes";

export const record = transaction => ({
    type: RECORD,
    payload: transaction
});

export const deleteTransaction = id => ({
    type: DELETE,
    payload: id
});

export const updateTransaction = transaction => ({
    type: UPDATE,
    payload: transaction
});
