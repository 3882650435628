import React from "react";
import style from "./Dashboard.module.scss";
import TransactionForm from "./TransactionForm";
import CurrencyList from "./CurrencyList";
import Total from "./Total";

const Dashboard = ({ currencies, transactions, record, id }) => {
    return (
        <div className={style["container"]}>
            <div style={{ padding: "50px" }}>
                <Total transactions={transactions} />
                <TransactionForm
                    currencies={currencies}
                    record={record}
                    id={id}
                />
            </div>
            <CurrencyList currencies={currencies} transactions={transactions} />
        </div>
    );
};

export default Dashboard;
