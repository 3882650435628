exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".App_container__2lGNg {\n  border-top: 3px solid #2196f3; }\n", ""]);

// exports
exports.locals = {
	"container": "App_container__2lGNg"
};