import { combineReducers } from "redux";
import transactionReducer from "./transactionReducer";
import currencyReducer from "./currencyReducer";
import idReducer from "./idReducer";

const rootReducer = combineReducers({
    currencies: currencyReducer,
    transactions: transactionReducer,
    id: idReducer
});

export default rootReducer;
