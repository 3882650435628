import React, { useState } from "react";
import style from "./TransactionList.module.scss";
import classnames from "classnames";
import { Link, navigate } from "@reach/router";
import { BinIcon, EditIcon } from "./Icons";
import TransactionForm from "./TransactionForm";

const TransactionList = ({
    currency,
    currencies,
    transactions,
    deleteTransaction,
    updateTransaction
}) => {
    const [editing, setEditing] = useState(-1);

    const currentTransactions = transactions.filter(
        transaction => transaction.currency === currency
    );
    if (currentTransactions.length === 0) navigate("/");

    const details = currencies[currency];
    return (
        <div className={style["container"]}>
            <div className={style["header"]}>
                <img src={details.logo} alt={`${details.label} logo`} />
                <div style={{ marginLeft: "10px" }}>
                    {details.label} Transactions
                </div>
            </div>
            <div className={style["list"]}>
                <div className={style["list-item-header"]}>
                    <div>#</div>
                    <div>Units</div>
                    <div style={{ textAlign: "end" }}>Price</div>
                </div>
                {currentTransactions.map(transaction => {
                    const { id, units, price } = transaction;
                    if (id === editing) {
                        return (
                            <TransactionForm
                                key={id}
                                mode="editing"
                                currencies={currencies}
                                updateTransaction={updateTransaction}
                                transaction={transaction}
                                id={id}
                                resetEditing={() => setEditing(-1)}
                            />
                        );
                    }
                    return (
                        <div key={id} className={style["list-item"]}>
                            <Cell>{id}</Cell>
                            <Cell>{units}</Cell>
                            <Cell position="end">{price}</Cell>
                            <div className={style["controls"]}>
                                <div className={style["icon-container"]}>
                                    <div onClick={() => setEditing(id)}>
                                        <EditIcon />
                                    </div>
                                    <div onClick={() => deleteTransaction(id)}>
                                        <BinIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <Link to="/">
                <div className={style["go-back"]}>Back to Dashboard</div>
            </Link>
        </div>
    );
};

const Cell = ({ position, children }) => {
    const className = classnames(style["cell"], {
        [style["end"]]: position === "end"
    });
    return <div className={className}>{children}</div>;
};

export default TransactionList;
