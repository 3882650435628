import React from "react";
import style from "./Button.module.scss";

const Button = ({ children, ...props }) => {
    return (
        <button className={style["button"]} {...props}>
            {children}
        </button>
    );
};

export const End = ({ children }) => {
    return <div className={style["end"]}>{children}</div>;
};

export default Button;
