import { RECORD } from "../actions/actionTypes";
import {
    binanceCoinIcon,
    bitcoinCashIcon,
    bitcoinIcon,
    cardanaIcon,
    eosIcon,
    ethereumIcon,
    litecoinIcon,
    stellarIcon,
    tetherIcon,
    xrpIcon,
    generic
} from "../../images";

const currencies = {
    bitcoin: { label: "Bitcoin", logo: bitcoinIcon },
    ethereum: { label: "Ethereum", logo: ethereumIcon },
    xrp: { label: "XRP", logo: xrpIcon },
    litecoin: { label: "Litecoin", logo: litecoinIcon },
    eos: { label: "EOS", logo: eosIcon },
    bitcoin_cash: { label: "Bitcoin Cash", logo: bitcoinCashIcon },
    binance_coin: { label: "Binance Coin", logo: binanceCoinIcon },
    tether: { label: "Tether", logo: tetherIcon },
    stellar: { label: "Stellar", logo: stellarIcon },
    cardano: { label: "Cardano", logo: cardanaIcon }
};

export default function currencyReducer(state = currencies, action) {
    switch (action.type) {
        case RECORD:
            const { currency } = action.payload;
            if (!state[currency]) {
                return {
                    ...state,
                    [currency]: { label: currency, logo: generic }
                };
            } else {
                return state;
            }
        default:
            return state;
    }
}
