exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Button_button__2Qv2G {\n  text-decoration: none;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  padding: 10px;\n  font-size: 17px;\n  width: 120px;\n  text-decoration: none;\n  height: 40px;\n  background-color: #2196f3;\n  color: #fff;\n  font-weight: 500;\n  border-radius: 3px; }\n  .Button_button__2Qv2G:hover {\n    background-color: #208ee5; }\n  .Button_button__2Qv2G:active {\n    background-color: #1e85d6; }\n\n.Button_end__3Kmxr {\n  display: -webkit-flex;\n  display: flex;\n  -webkit-justify-content: flex-end;\n          justify-content: flex-end;\n  -webkit-align-items: flex-end;\n          align-items: flex-end; }\n", ""]);

// exports
exports.locals = {
	"button": "Button_button__2Qv2G",
	"end": "Button_end__3Kmxr"
};