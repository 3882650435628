import React from "react";
import convertPrice from "../convertPrice.js";

const Total = ({ transactions }) => {
    const total =
        transactions.length === 0
            ? 0
            : transactions.reduce((acc, transaction) => {
                  return acc + convertPrice(transaction.price);
              }, 0);
    return (
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <div>Total Spent</div>
            <div style={{ fontSize: "24px" }}>{`$${total} AUD`}</div>
        </div>
    );
};

export default Total;
