exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CurrencyList_container__i4zq3 {\n  display: -webkit-flex;\n  display: flex;\n  -webkit-flex-wrap: wrap;\n          flex-wrap: wrap;\n  padding: 10px;\n  padding-top: 38px;\n  height: 100px; }\n", ""]);

// exports
exports.locals = {
	"container": "CurrencyList_container__i4zq3"
};