import { RECORD, DELETE, UPDATE } from "../actions/actionTypes";

export default function transactionReducer(state = [], action) {
    switch (action.type) {
        case RECORD:
            return [...state, action.payload];
        case DELETE:
            const deleteIndex = state.findIndex(
                transaction => transaction.id === action.payload
            );
            return [
                ...state.slice(0, deleteIndex),
                ...state.slice(deleteIndex + 1)
            ];
        case UPDATE:
            const updateIndex = state.findIndex(
                transaction => transaction.id === action.payload.id
            );
            return [
                ...state.slice(0, updateIndex),
                action.payload,
                ...state.slice(updateIndex + 1)
            ];
        default:
            return state;
    }
}
