import React from "react";
import style from "./CurrencyOverview.module.scss";

const CurrencyOverview = ({
    details,
    totalTransactions,
    totalPrice,
    totalUnits
}) => {
    return (
        <div className={style["container"]}>
            <div className={style["header"]}>
                <img
                    src={details.logo}
                    alt={`${details.label} logo`}
                    height="20"
                    width="20"
                />
                <div style={{ marginLeft: "7px" }}>{details.label}</div>
            </div>
            <div className={style["details"]}>
                <ValueDisplay title="# TX" value={totalTransactions} />
                <ValueDisplay title="Units" value={totalUnits} />
                <ValueDisplay title="Price" value={`$${totalPrice} AUD`} />
            </div>
        </div>
    );
};

const ValueDisplay = ({ title, value }) => (
    <div>
        <div className={style["value-title"]}>{title}</div>
        <div className={style["value"]}>{value}</div>
    </div>
);

export default CurrencyOverview;
